<template>
  <div>
    <hedaer @setSelect="setItem"></hedaer>
    <div class="business item">
      <ul class="home-tab wow fadeInUp" data-wow-delay="0.3s">
        <li :class="{ selectBab: selectBab == 1 }" @click="selectBab = 1">
          植发
        </li>
        <li :class="{ selectBab: selectBab == 2 }" @click="selectBab = 2">
          服务保障体系
        </li>
        <li :class="{ selectBab: selectBab == 3 }" @click="selectBab = 3">
          养发
        </li>
      </ul>
      <div class="home-line wow fadeInUp" data-wow-delay="0.3s"></div>
      <div v-show="selectBab == 1">
        <div class="Profile-title wow fadeInUp" data-wow-delay="0.4s">植发</div>
        <div class="Profile-en wow fadeInUp" data-wow-delay="0.5s">
          HAIR TRANSPLANT
        </div>
        <p class="LoveHelpStudents-text wow fadeInUp" data-wow-delay="0.6s">
          根据中国整形美容协会发布的《毛发移植规范》团体标准，植发主要分为毛囊提取和种植两个维度：毛囊提取主要是毛囊单位头皮条切取术（FUT）和毛囊单位钻取术（FUE），毛囊种植主要是镊子种植、种植笔和即插即种。
        </p>
        <p class="LoveHelpStudents-text wow fadeInUp">
          以上技术均需在手术前剃发，对工作、生活有一定的影响。现在行业最前沿的技术是不剃发植发，实现了毛囊提取、种植技术的双重革新。目前，碧莲盛采用的是毛囊单位钻取术、即插即种和不剃发植发。
        </p>
        <div
          style="display: flex; flex-wrap: wrap; justify-content: space-between"
        >
          <div
            class="transplant transplantBg wow"
            style="
              background: url('https://static.drlianzhuren.com/brandWeb//business/bu1.jpg')
                no-repeat;
              background-size: 100% 100%;
            "
          >
            <div class="transplant-top">剃发</div>
            <div class="transplant-textOne">加密</div>
            <div class="transplant-text">即插即种</div>
            <div class="transplant-line"></div>
          </div>
          <div
            class="transplant transplantBg wow"
            style="
              background: url('https://static.drlianzhuren.com/brandWeb//business/bu2.jpg')
                no-repeat;
              background-size: 100% 100%;
            "
          >
            <div class="transplant-top">剃发</div>
            <div class="transplant-textOne">精密</div>
            <div class="transplant-text">即插即种</div>
            <div class="transplant-line"></div>
          </div>
          <div
            class="transplant transplantBg wow"
            style="
              background: url('https://static.drlianzhuren.com/brandWeb//business/bu3.jpg')
                no-repeat;
              background-size: 100% 100%;
              margin-bottom: 0;
            "
          >
            <div class="transplant-top">不剃发</div>
            <div class="transplant-textOne">加密</div>
            <div class="transplant-text">不剃发植发</div>
            <div class="transplant-line"></div>
          </div>
          <div
            class="transplant transplantBg wow"
            style="
              background: url('https://static.drlianzhuren.com/brandWeb//business/bu4.jpg')
                no-repeat;
              background-size: 100% 100%;
              margin-bottom: 0;
            "
          >
            <div class="transplant-top">不剃发</div>
            <div class="transplant-textOne">精密</div>
            <div class="transplant-text">不剃发植发</div>
            <div class="transplant-line"></div>
          </div>
        </div>
      </div>
      <div v-show="selectBab == 2">
        <div class="Profile-title wow fadeInUp">服务保障体系</div>
        <div class="Profile-en wow fadeInUp">SERVICE SYSTEM</div>
        <div class="wow fadeInUp" data-wow-delay="0.1s">
          <div class="ServiceGuarantee">
            <img src="../assets/business/1.jpg" alt="" />
            <div class="ServiceGuarantee-box">
              <div class="ServiceGuarantee-title">精准定制</div>
              <div class="ServiceGuarantee-text">
                建立术前沟通机制，根据发友年龄、性别、五官比例及需求量身定制植发方案。
              </div>
            </div>
          </div>
        </div>
        <div class="wow fadeInUp" data-wow-delay="0.2s">
          <div class="ServiceGuarantee">
            <img src="../assets/business/2.jpg" alt="" />
            <div class="ServiceGuarantee-box">
              <div class="ServiceGuarantee-title">无时无刻</div>
              <div class="ServiceGuarantee-text">
                7*24小时，全年365天，全天侯面向发友提供专业毛发健康咨询与服务。
              </div>
            </div>
          </div>
        </div>
        <div class="wow fadeInUp" data-wow-delay="0.3s">
          <div class="ServiceGuarantee">
            <img src="../assets/business/3.jpg" alt="" />
            <div class="ServiceGuarantee-box">
              <div class="ServiceGuarantee-title">合同保障</div>
              <div class="ServiceGuarantee-text">
                术前签订八大服务承诺协议，从法律效益上，明确了发友在植发术前、术中和术后的权益保障。
              </div>
            </div>
          </div>
        </div>
        <div class="wow fadeInUp" data-wow-delay="0.4s">
          <div class="ServiceGuarantee" style="margin-bottom: 0">
            <img src="../assets/business/4.jpg" alt="" />
            <div class="ServiceGuarantee-box">
              <div class="ServiceGuarantee-title">保险保障</div>
              <div class="ServiceGuarantee-text">
                同中国平安保险达成战略合作，为医护人员提供百万医疗责任险，让发友放心植发，安心变美。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="selectBab == 3">
        <div class="Profile-title wow fadeInUp">养发</div>
        <div class="Profile-en wow fadeInUp">HAIR CARE</div>
        <p class="LoveHelpStudents-text wow fadeInUp">
          碧莲盛养发，始于2001年，新华网国潮优选品牌，碧莲盛医疗旗下专业养发品牌。21年来，碧莲盛始终专注于毛发健康领域的研究，在深耕植发的同时，积累了丰富的非手术治疗脱发经验和成功案例，帮助近百万客户解决了脱发问题。
        </p>
        <p class="LoveHelpStudents-text wow fadeInUp">
          碧莲盛养发坚守医疗本质，以客户为中心，独家首创6R头皮管理体系，科学制定7步头皮管理流程，为每一个客户量身定制毛发健康管理方案，提供专业分析和养护指导，由内而外地改善毛发健康，一站式解决毛发健康问题。
        </p>
        <img
          src="https://static.drlianzhuren.com/brandWeb/home/m-2.jpg"
          alt=""
          class="development wow fadeInUp"
        />
      </div>
    </div>
    <Footer @setItem="setItem"></Footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectBab: 1
    }
  },
  methods: {
    setItem (a) {
      this.selectBab = a
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.selectBab = this.$route.params.id + 1
    }
  }
}
</script>

<style lang="scss">
.business {
  background: #f9fafb;
}
.transplant {
  width: 240px;
  height: 240px;
  padding: 0.2rem 0 0 0.6296rem;
  margin-bottom: 18px;

  .transplant-top {
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #ffffff;
    padding: 3px 7px;
    border: solid 1px #ffffff;
  }
  .transplant-textOne {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 35px;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .transplant-text {
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .transplant-line {
    width: 24px;
    height: 2px;
    background-color: #ffffff;
    margin-top: 35px;
  }
}
.ServiceGuarantee {
  margin-bottom: 20px;
  img {
    width: 500px;
    height: 284px;
    vertical-align: middle;
  }
  .ServiceGuarantee-box {
    width: 500px;
    padding: 32px 23px;
    background-color: #ffffff;
    .ServiceGuarantee-title {
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      margin-bottom: 15px;
      letter-spacing: 0px;
      color: #363636;
    }
    .ServiceGuarantee-text {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #707070;
    }
  }
}
.development {
  width: 500px;
  height: 325px;
  vertical-align: middle;
  object-fit: cover;
}
.transplantBg {
  animation: Culture 0.3s linear both;
  transition: animation animation 0.3s;
}
@keyframes Culture {
  0% {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20%;
  }

  100% {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}
</style>
